<template>
  <v-img :src="require('~/assets/img/background-images.svg')" height="100vh" width="100vw" class="px-10 py-8">
    <v-row class="flex-column fill-height" no-gutters>
      <v-col class="shrink">
        <v-img :src="require('~/assets/img/logo.svg')" alt="Vervoerregio Amsterdam" max-width="320" max-height="54"/>
      </v-col>
      <v-col>
        <v-row class="justify-center align-end align-sm-center fill-height text-center">
          <v-col cols="10" sm="7" md="4" lg="3">
            <h1 class="primary--text text-uppercase">Portaal vervoerregio</h1>
            <form method="post" class="pt-12" @submit.prevent="submit">
              <v-text-field
                id="email"
                v-model="form.email"
                :error-messages="errors.email"
                type="email"
                label="E-mailadres"
                outlined
                color="primary"
                autocomplete="email"
              />
              <v-text-field
                id="password"
                v-model="form.password"
                @click:append="passwordVisible = !passwordVisible"
                :error-messages="errors.password"
                :append-icon="passwordVisible ? '$vuetify.icons.visibility' : '$vuetify.icons.visibility_off'"
                :type="passwordVisible ? 'text' : 'password'"
                label="Wachtwoord"
                outlined
                color="primary"
                autocomplete="current-password"
              />
              <v-col class="text-center">
                <v-btn
                  :loading="loading"
                  type="submit"
                  color="primary darken-1"
                  depressed
                >
                  Inloggen
                </v-btn>
              </v-col>
            </form>
            <nuxt-link :to="{name: 'wachtwoord-vergeten'}" class="caption">Wachtwoord vergeten?</nuxt-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-img>
</template>

<script>
export default {
  layout: 'auth',
  middleware: 'guest',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      passwordVisible: false,
      loading: false,
    };
  },
  methods: {
    async submit() {
      // Als Pusher key niet defined staat werkt de hele login form het niet (PUSHER_APP_KEY in .env)
      this.loading = true;
      try {
        await this.$auth.loginWith('local', {
          data: this.form,
        });
      } catch (e) {
        if (e.status !== 422) {
          this.$store.commit('notify/error', 'Er is een fout opgetreden.');
        }
        this.loading = false;
      }
    },
  },
  head: {
    title: 'Inloggen',
  },
};
</script>
